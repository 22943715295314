export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Sop',
  },
  {
    title: 'Data Quality',
    route: 'dataquality',
    icon: 'CheckCircleIcon',
    children: [
      {
        title: 'Product Master',
        route: 'product-master',
        action: 'read',
        resource: 'Admin',
      }
    ],
  },
  {
    title: 'Visuals',
    route: 'visual',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Database',
        route: 'visual-database',
        action: 'read',
        resource: 'Sop',
      },
      {
        title: 'Prepare Summary',
        route: 'prepare-summary',
        action: 'read',
        resource: 'Sop',
      },
      {
        title: 'SOP Simulation',
        route: 'sop-simulation',
        action: 'read',
        resource: 'Sop',
      },
      {
        title: 'Cobertura',
        route: 'cobertura',
        action: 'read',
        resource: 'Sop',
      },
    ],
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Settings Sop',
    route: 'settings-sop',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'Admin',
  },
]
